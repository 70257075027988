<template>
        <div>
            <div class="leisure_button_group_sub">
                <router-link tag="button" class="bw5"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_FX_1M}"
                             :to="{path:'/leisure/fx1m', query: {t: new Date().getTime()}}">fx 1m</router-link>

                <router-link tag="button" class="bw5"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_FX_2M}"
                             :to="{path:'/leisure/fx2m', query: {t: new Date().getTime()}}">fx 2m</router-link>

                <router-link tag="button" class="bw5"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_FX_3M}"
                             :to="{path:'/leisure/fx3m', query: {t: new Date().getTime()}}">fx 3m</router-link>

                <router-link tag="button" class="bw5"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_FX_4M}"
                             :to="{path:'/leisure/fx4m', query: {t: new Date().getTime()}}">fx 4m</router-link>

                <router-link tag="button" class="bw5"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_FX_5M}"
                             :to="{path:'/leisure/fx5m', query: {t: new Date().getTime()}}">fx 5m</router-link>
            </div>
            <div class="leisure_button_group_sub">
                <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NAMED_SADALI}"
                             :to="{path:'/leisure/namedsadali', query: {t: new Date().getTime()}}">N 사다리</router-link>
                <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NAMED_DALIDALI}"
                             :to="{path:'/leisure/nameddalidali', query: {t: new Date().getTime()}}">N 다리다리</router-link>
                <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NAMED_SNAIL}"
                             :to="{path:'/leisure/namedsnail', query: {t: new Date().getTime()}}">N 달팽이</router-link>
            </div>
        </div>




</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureCompNamedGameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>